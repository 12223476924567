<template>
    <section>

        <!-- Everyday Modal -->
        <div class="modal fade" id="everyDayModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
            aria-labelledby="everyDayModalLabel" aria-hidden="true" v-for="(item, index) in getSubscription"
            :key="index" v-if="type == 1">

            <div class="modal-dialog">
                <div class="modal-content every-modal">
                    <div class="modal-header">
                        <h3 class="modal-title fs-5" id="everyDayModalLabel">{{ $t("Everyday") }}</h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            @click="onClose"></button>
                    </div>
                    <div class="modal-body">
                        <div>
                            <ul class="subs-list | d-flex flex-column gap-3">
                                <li class="d-flex gap-3 justify-content-between py-3">
                                    <p>{{ $t("Quantity") }}</p>
                                    <div class="number d-flex align-items-center">
                                        <!-- :value="item.quantity" /><span -->
                                        <span class="minus d-flex justify-content-center align-items-center"
                                            @click="minusItem(item)">-</span><input readonly type="text"
                                            :value="item.quantity" /><span
                                            class="plus d-flex justify-content-center align-items-center"
                                            @click="plusItem(item, index)">+</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="modal-footer full-btn d-block">
                        <button @click="setType(1)">{{ $t("Submit") }}</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- setInterval Modal -->
        <div class="modal fade" v-if="type == 2" id="everyDayModal" tabindex="-1" data-bs-backdrop="static"
            data-bs-keyboard="false" aria-labelledby="deliveryDayModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title fs-5" id="deliveryDayModalLabel">
                            {{ $t("Select delivery day") }}
                        </h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            @click="onClose"></button>
                    </div>
                    <div class="modal-body">
                        <div>
                            <ul class="subs-list form__group | d-flex flex-column">
                                <li class="form__radio-group | d-flex gap-3 justify-content-between"
                                    v-for="(intervalOption, optionIndex) in intervalOptions"
                                    :key="'intervalOption_' + optionIndex">
                                    <input type="radio" :id="'classic' + optionIndex + 'Option' + optionIndex"
                                        :value="intervalOption" class="form__radio-input" name="interval_day"
                                        :checked="intervalOption.isChecked"
                                        @click="updateQuantity(optionIndex, intervalOption.value, intervalOption.isModal)" />
                                    <!-- {{ intervalInput ? intervalInput : intervalOption.label }} -->
                                    <label :for="'classic' + optionIndex + 'Option' + optionIndex"
                                        class="form__label-radio">
                                        <span class="form__radio-button"></span>
                                        <!-- {{ intervalOption.label }} -->
                                        <!-- {{ intervalOption.isModal }} -->
                                        {{ intervalOption.isModal && intervalOption.isChecked ? `Every
                                        ${intervalModalvalue}th day` :
                                            intervalOption.label }}
                                    </label>

                                    <div class="number d-flex align-items-center">
                                        <span class="minus d-flex justify-content-center align-items-center"
                                            @click="minusIntervalItem(optionIndex, intervalOption.value)">-</span>
                                        <input readonly type="text" :value="intervalOption.quantity" />
                                        <span class="plus d-flex justify-content-center align-items-center"
                                            @click="plusIntervalItem(optionIndex, intervalOption.value, intervalOption.isModal)">+</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>


                    <!-- <div >
                        <h3>Enter your custom nth day</h3>
                        <input v-model="nthDayInput" placeholder="Enter a number" />
                        <button @click="saveNthDay">Save</button>
                    </div> -->

                    <div class="modal-footer full-btn d-block">
                        <button @click="setType(2)">{{ $t("Submit") }}</button>
                    </div>
                </div>
            </div>
        </div>


        <div class="modal fade" id="subInervalModal" tabindex="-1" aria-labelledby="subInervalModalLabel"
            aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog">
                <div class="modal-content h-auto border-0">
                    <div class="modal-body">
                        <h4>{{ $t("Enter the interval") }}</h4>
                        <input type="number" class="form-control mt-3" id="intervalInput" v-model="intervalInput"
                            placeholder="interval" @input="validateInput" />
                        <div class="d-flex align-items-center justify-content-end mt-4">
                            <a href="javascript:void(0)" class="btn me-4" data-bs-dismiss="modal" aria-label="Close"
                                @click="onClose">Cancel</a>
                            <a @click="addIntrvalDay" href="javascript:void(0);" class="btn btn--primary cncl-tip">Add
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" v-if="type == 3" id="everyDayModal" tabindex="-1" data-bs-backdrop="static"
            data-bs-keyboard="false" aria-labelledby="deliveryDayTwoModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title fs-5" id="deliveryDayTwoModalLabel">
                            {{ $t("Select delivery day") }}
                        </h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            @click="onClose"></button>
                    </div>
                    <div class="modal-body">
                        <div>
                            <ul class="subs-list custom-checkbox | d-flex flex-column">
                                <li class="d-flex gap-3 justify-content-between select-item"
                                    v-for="(day, index) in selectedDays" :key="day.key">
                                    <div class="form-group">
                                        <input v-if="!isDayClosed(day.day)" type="checkbox" :id="'checkbox' + day.key"
                                            :value="day.day" class="form-check-input" v-model="day.isChecked"
                                            @change="updateSelectedDays(index)" />
                                        <label :for="'checkbox' + day.key" class="form-check-label">
                                            {{ day.day }}
                                        </label>
                                    </div>
                                    <span v-if="isDayClosed(day.day)"
                                        class="text-danger d-flex align-items-center">Closed</span>
                                    <div v-else class="number d-flex align-items-center">
                                        <span class="minus d-flex justify-content-center align-items-center"
                                            @click="minusCustomItem(index)">-</span>
                                        <input readonly type="text" :value="day.quantity" disabled />
                                        <span class="plus d-flex justify-content-center align-items-center"
                                            @click="plusCustomItem(index)">+</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="modal-footer full-btn d-block">
                        <button @click="setType(3)">{{ $t("Submit") }}</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- <subscription :subscription_type="subscription_type"></subscription> -->
    </section>

</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import moment from "moment";
import noImge from "@/assets/images/place_holder.png";
import { requiredIf } from "vuelidate/lib/validators";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import Vue from "vue";
import Subscription from './Subscription.vue';

export default {
    props: ['type', 'closeDays'],
    components: { Subscription },
    data() {
        return {
            selectedCustomisation: {
                customize_type: {},
            },
            everyDay_quantity: 1,
            showModal: false,
            intervalInput: 0,
            selectedDayName: '',
            intervalModalvalue: "",
            nthDayInput: '',
            settings: {
                autoplay: true,
                dots: false,
                arrows: true,
                infinite: true,
                speed: 800,
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 0,
                centerMode: false,
                centerPadding: "40px",
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            infinite: true,
                            dots: true,
                        },
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            initialSlide: 1,
                        },
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                ],
            },
            subscription_start_date: null,
            subscription_end_date: null,
            subscription_type: 0,
            interval: {},
            intervalOptions: [
                { value: "2", label: "Every 2nd day", quantity: 1, isChecked: true, isModal: false },
                { value: "3", label: "Every 3rd day", quantity: 0, isChecked: false, isModal: false },
                { value: "4", label: "Every 4th day", quantity: 0, isChecked: false, isModal: false },
                { value: "n", label: "Every nth day", quantity: 0, isChecked: false, isModal: true },
            ],
            tags: [],
            days: [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
            ],
            selectedDays: [
                { key: "Sun", day: "Sunday", isChecked: true, quantity: 1 },
                { key: "Mon", day: "Monday", isChecked: true, quantity: 1 },
                { key: "Tue", day: "Tuesday", isChecked: true, quantity: 1 },
                { key: "Wed", day: "Wednesday", isChecked: true, quantity: 1 },
                { key: "Thu", day: "Thursday", isChecked: true, quantity: 1 },
                { key: "Fri", day: "Friday", isChecked: true, quantity: 1 },
                { key: "Sat", day: "Saturday", isChecked: true, quantity: 1 },
            ],

            date_day: [
                { key: "Sun", day: "Sunday", value: 0 },
                { key: "Mon", day: "Monday", value: 0 },
                { key: "Tue", day: "Tuesday", value: 0 },
                { key: "Wed", day: "Wednesday", value: 0 },
                { key: "Thu", day: "Thursday", value: 0 },
                { key: "Fri", day: "Friday", value: 0 },
                { key: "Sat", day: "Saturday", value: 0 },
            ],
            nthDayValue: 0,
            customize_skelloader: true,
            everyDayModal: false,
            intervalDayModal: false,
            addInterval: "",
            customDayModal: false,
            moment: moment,
            productDetail: null,
            restaurantData: "",
            getDeliveryDay: "",
            gstMaxtax: 0,
            subscriptionItemCount: 1,
            promoDiscount: 0,
            restaurantId: 0,
            itemId: 0,
            latitude: "",
            longitude: "",
            restaurantLat: "",
            restaurantLong: "",
            page: "review_page",
            isAsapEnable: false,
            timeFormat: "HH:mm:ss",
            isRestaurantClosed: false,
            isSameDayDelivery: false,
            is_btn_spinner: true,
            images: [],
            selectedCustomisation: {
                customize_type: {},
            },
            selectedCheckbox: [],
            orderImagesId: "",
            loading: false,
            noImge,
            instructionPlaceholder: "",
            packagingCharge: 0,
            convince_charge: 0,
            isTemplateShow: false,
            isAlert: false,
            isCustomizationVisible: false,
            subscriptionObj: {
                restaurant_id: 0,
                vendor_id: null,
                user_id: "",
                // user_id: this.$auth.user.user_id,
                wallet_amount: 0,
                address_id: null,
                restaurant_service_fee: 0,
                device_type: "WEB",
                is_langauge: "en",
                app_version_code: 3.4,
                payment_method: "Wallet",
                subscription_type: 0,
                interval: 0,
                custom_days: [],
                start_date: null,
                end_date: null,
                restaurant_item_details: null,
                payment_type: "wallet",
            },
        };
    },
    // watch: {
    //     "intervalModalvalue": function () {
    //     }
    // },
    computed: {
        ...mapGetters("product", ["getSubscription", "products",]),
        ...mapGetters("restaurant", ["restaurantDetails"]),
        ...mapGetters("user", ["userWallet"]),

        onModalCloseFun(value) {
            this.isAlert = false;
        },


        filteredSubscriptions() {
            return this.getSubscription.filter((item) => item.price !== "0");
        },

        // day_date_value() {
        //     if (this.subscription_type == 3) {
        //         return this.selectedDays.map((day) => ({
        //             // key: day.key,
        //             day: day.day,
        //             // day: this.date_day.find((d) => d.key == day.key).key,
        //             quantity: day.isChecked ? day.quantity : 0,
        //         }));
        //     }
        //     return null;
        // },

        custome_full_days() {
            if (this.subscription_type == 3) {
                return this.selectedDays.map((day) => ({
                    day: day.day,
                    quantity: day.isChecked ? day.quantity : 0,
                }));
            }
            return null;
        },

        day_date_value() {
            if (this.subscription_type == 3) {
                return this.selectedDays.map((day) => ({
                    // key: day.key,
                    day: day.day,
                    // day: this.date_day.find((d) => d.key == day.key).key,
                    quantity: day.isChecked ? day.quantity : 0,
                }));
            }
            return null;
        },
    },
    created() {
        if (localStorage.getItem('subscriptionModal_data')) {
            let object = JSON.parse(localStorage.getItem('subscriptionModal_data'));
            if (object.type == 1) {
                this.subscriptionData = object.data
                // this.addTOSubscription(object.data[0])
            } else if (object.type == 2) {
                this.intervalOptions = object.data
            } else if (object.type == 3) {
                this.selectedDays = object.data
            }
        }
    },
    mounted() {
        $("#everyDayModal").modal("show");
        if (JSON.parse(localStorage.getItem('setIntervalday'))) {
            this.intervalInput = JSON.parse(localStorage.getItem('setIntervalday')).value || "n";
            this.intervalModalvalue = this.intervalInput;
        }
    },
    methods: {
        ...mapActions("product", ["getProductDetails", "addSubscriptionItem", "addTOSubscription"]),
        ...mapActions("restaurant", ["getRestaurantDetails"]),
        ...mapActions("order", ["postUploadOrderImages", "postremoveOrderImages"]),
        ...mapMutations("order", ["pickupdelivery"]),

        ...mapGetters("product", ["getSubscriptionItem"]),

        isDayClosed(date) {
            const isClosed = this.closeDays.includes(date);
            // Update selectedDays accordingly
            this.selectedDays.forEach((day) => {
                if (day.day == date) {
                    // day.isChecked = !isClosed; // Uncheck if closed, keep checked otherwise
                    day.quantity = isClosed ? 0 : day.quantity; // Set quantity to 0 if closed
                }
            });
            return isClosed; // Return whether the day is close
        },

        onClose() {
            this.$emit('subscriptionModalClose', true)
            // this.$emit('subInervalModal', true)
        },
        validateInput() {
            // Ensure only two digits are allowed
            if (this.intervalInput.length > 2) {
                this.intervalInput = this.intervalInput.slice(0, 2);
            }
        },

        //Increament Item
        plusItem(item, index) {
            // this.everyDay_quantity = item.quantity
            if (item.itemTotalQuantity > item.quantity) {
                item.quantity++;
                this.everyDay_quantity = item.quantity;
                // this.getSubscription[0].quantity = item.quantity;
            } else {
                alert(this.$t("Maximum items reached"));
            }
        },
        //Decrement Item
        minusItem(cartProduct) {
            if (cartProduct.quantity < 1) {
                return;
            } else {
                cartProduct.quantity--;
                this.everyDay_quantity = cartProduct.quantity;
            }
        },

        updateSelectedDays(index) {
            if (this.selectedDays[index].isChecked) {
                this.selectedDays[index].quantity = 1;
            } else {
                this.selectedDays[index].quantity = 0;
            }
        },
        plusCustomItem(index) {
            if (!this.selectedDays[index].isChecked) {
                this.selectedDays[index].isChecked = true;
            }
            this.selectedDays[index].quantity++;
        },

        minusCustomItem(index) {
            if (
                this.selectedDays[index].isChecked &&
                this.selectedDays[index].quantity > 0
            ) {
                this.selectedDays[index].quantity--;
                if (this.selectedDays[index].quantity === 0) {
                    this.selectedDays[index].isChecked = false;
                }
            }
        },

        updateQuantity(optionIndex, value, flag) {
            // if (JSON.parse(localStorage.getItem('setIntervalday'))) {
            //     this.intervalInput = JSON.parse(localStorage.getItem('setIntervalday')).value || "n";
            //     this.intervalModalvalue = this.intervalInput;
            // }
            if (flag) {
                // Hide the deliveryDayModal first
                $("#everyDayModal").modal("hide");
                // Wait for everyDayModal to fully hide before showing driverTipModal
                $("#everyDayModal").on('hidden.bs.modal', () => {
                    setTimeout(() => {
                        $("#subInervalModal").modal("show");
                        if (value == 'n') {
                            this.intervalInput = ""
                        }
                    }, 200); // Optional delay to ensure smooth transition
                });
                this.showModal = true;

            } else {
                // If 'n' is not selected, ensure the modal logic is reversed.
                $("#subInervalModal").modal("hide");
                this.showModal = false;
            }

            this.intervalOptions.forEach((option, i) => {
                if (i === optionIndex) {
                    if (option.quantity === 0) {
                        option.quantity = 1;
                    }
                    option.isChecked = true;
                } else {
                    option.isChecked = false;
                    option.quantity = 0;
                }
            });
        },

        plusIntervalItem(index, value, flag) {
            if (flag && this.intervalOptions[3].isChecked == false) {
                // Hide the deliveryDayModal first
                $("#everyDayModal").modal("hide");
                // Wait for everyDayModal to fully hide before showing driverTipModal
                $("#everyDayModal").on('hidden.bs.modal', () => {
                    setTimeout(() => {
                        $("#subInervalModal").modal("show");
                    }, 200); // Optional delay to ensure smooth transition
                });
                this.showModal = true;
            } else {
                // If 'n' is not selected, ensure the modal logic is reversed.
                $("#subInervalModal").modal("hide");
                this.showModal = false;
            }

            if (this.intervalOptions[index].quantity === 0) {
                this.intervalOptions[index].quantity++;
                this.intervalOptions[index].isChecked = true;
                this.updateQuantity(index);
            } else {
                this.intervalOptions[index].quantity++;
            }
        },

        minusIntervalItem(index, value) {
            if (this.intervalOptions[index].quantity > 0) {
                this.intervalOptions[index].quantity--;
            }
            if (this.intervalOptions[index].quantity === 0) {
                this.intervalOptions[index].isChecked = false;
            }
        },

        //Subscription Type
        setType(type) {
            this.subscription_type = type;
            // Check if any of the everyDay_quantity have a quantity of 0
            if (this.subscription_type == 1) {
                if (this.everyDay_quantity == null || this.everyDay_quantity == 0) {
                    Vue.toasted.error("Please Provide Quantity", {
                        duration: 2000,
                    });
                    return
                }
            }

            if (this.subscription_type == 2) {
                const hasZeroQuantity = this.intervalOptions.some(item => item.quantity > 0);
                if (hasZeroQuantity == false) {
                    Vue.toasted.error("Please Provide Quantity", {
                        duration: 2000,
                    });
                    return // Stop execution if the condition is met
                }
            }

            if (this.subscription_type == 3) {
                const hasZeroQuantity = this.selectedDays.some(item => item.quantity > 0);
                if (hasZeroQuantity == false) {
                    Vue.toasted.error("Please Provide Quantity", {
                        duration: 2000,
                    });
                    return // Stop execution if the condition is met
                }
            }
            // Check if any of the intervalOptions have a quantity of 0

            if (type == 2) {
                this.intervalOptions.forEach((e) => {
                    if (e.isChecked == true) {
                        this.interval = e;
                        if (e.isModal && e.isChecked == true) {
                            this.interval.value = this.intervalInput;
                            this.nthDayValue = { ...this.interval };
                        }
                        localStorage.setItem(
                            "setIntervalday",
                            JSON.stringify(this.interval)
                        );
                    }

                });
            }
            $("#subInervalModal").modal("hide");
            this.createSubsciption()
        },

        getNexDayQuantity(selectedDays) {
            const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
            var todayIndex = new Date().getDay();
            // todayIndex = (todayIndex) % 7;
            todayIndex = (todayIndex) % 7;
            for (let i = 1; i <= 7; i++) {
                const nextIndex = (todayIndex + i) % 7;
                const nextDay = daysOfWeek[nextIndex];
                const nextDayData = this.selectedDays.find((day) => day.key === nextDay);
                if (nextDayData && nextDayData.quantity != 0) {
                    return nextDayData.quantity;
                }
            }
            return 0;
        },

        addIntrvalDay() {
            if (this.intervalInput == null || this.intervalInput == "") {
                Vue.toasted.error("Please enter value", {
                    duration: 2000,
                });
                return
            }
            if (this.intervalInput <= 4) {
                Vue.toasted.error("Interval must be greater than 4", {
                    duration: 2000,
                });
                return
            }
            this.intervalModalvalue = Number(this.intervalInput);
            $("#subInervalModal").modal("hide");
            $("#everyDayModal").modal("show");
        },

        createSubsciption() {
            $("#everyDayModal").modal("hide");
            let data;
            if (this.type == 1) {
                data = this.getSubscription;
                var subscriptionData = this.getSubscriptionItem();
                if (subscriptionData && subscriptionData[0] && subscriptionData[0].quantity) {
                    subscriptionData[0].quantity = this.getSubscription[0].quantity;
                    this.addSubscriptionItem(subscriptionData[0]);
                }
            } else if (this.type == 2) {
                data = this.intervalOptions;
                data.forEach((e) => {
                    if (e.isChecked == true) {
                        this.interval = e.quantity;
                        // this.addInterval = e.value
                    }
                    // if (e.value == 'n' && e.isChecked == true) {
                    //     this.interval.value = this.intervalInput;
                    // }
                });

                var subscriptionData = this.getSubscriptionItem();
                if (subscriptionData && subscriptionData[0] && subscriptionData[0].quantity) {
                    subscriptionData[0].quantity = this.interval;

                    // data.forEach((e) => {
                    //     if (e.value === 'n' && e.isChecked == true) {
                    //         this.addInterval = this.intervalModalvalue;
                    //         this.addInterval = e.value
                    //     }
                    // });


                    // subscriptionData[0].value = this.addInterval;
                    // this.interval.value = subscriptionData[0].value
                    this.addSubscriptionItem(subscriptionData[0]);
                }

            } else if (this.type == 3) {
                data = this.selectedDays;
                var subscriptionData = this.getSubscriptionItem();
                if (subscriptionData && subscriptionData[0] && subscriptionData[0].quantity) {
                    subscriptionData[0].quantity = this.getNexDayQuantity(this.day_date_value);
                    this.addSubscriptionItem(subscriptionData[0]);
                }
            } else {
                data = false;
            }
            this.$emit('selectedData', data);
            if (data) {
                localStorage.setItem("subscribe-custom", JSON.stringify(this.custome_full_days));
                localStorage.setItem('subscriptionModal_data', JSON.stringify({
                    type: this.type,
                    data: data,

                }));
            }
            this.$emit('subscriptionModalClose', true)
            this.$emit('isShowText', true)
        },

    }
}
</script>

<style>
.modal-content {
    height: 60vh
}

.select-item {
    align-items: center;
    border-bottom: 1px solid var(--theme-secondary);
}

.select-item .form-group {
    border: 0;
}
</style>